<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>用户管理</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>用户列表</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>提现管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>余额明细</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>用户信息详情</span>
        </div>
        <div class="title mt-40">
          <span>用户信息</span>
        </div>
        <div class="mt-20">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="id"
              label="ID"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="date"
              label="头像"
              width="150"
              align="center"
            >
            <template slot-scope="scope">
                <img style="width: 60%" :src="scope.row.avatarUrl" alt="" />
              </template>
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="昵称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="所在地"
              width="150"
              align="center"
            >
            <template slot-scope="scope">
              {{scope.row.country}}{{scope.row.province}}{{scope.row.city}}
            </template>
            </el-table-column>
            <el-table-column
              prop="created_at"
              label="注册时间"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20" v-if="tableData[0].bank">
            <span>银行卡信息</span>
        </div>
        <div class="mt-20" v-if="tableData[0].bank">
          <el-table :data="tableData" border>
            <el-table-column
              prop="bank.bank_account"
              label="姓名"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bank.bank_name"
              label="开户行"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="bank.phone"
              label="手机号"
              width="150"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="bank.id_card"
              label="身份证"
              width="200"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="bank.id_card"
              label="年龄段"
              width="200"
              align="center"
            >
            <template slot-scope="scope">
              <span v-if="scope.row.bank.age_group == 0">18-30</span>
              <span v-if="scope.row.bank.age_group == 1">31-50</span>
              <span v-if="scope.row.bank.age_group == 2">50+</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="bank.bank_card"
              label="银行卡卡号"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20">
            <span>余额概况</span>
        </div>
        <div class="mt-20">
            <p>可提现余额：{{tableData[0].money}}</p>
            <p>冻结余额：{{tableData[0].frozen_money}}</p>
            <p>待收入余额：{{tableData[0].sell_money}}</p>
            <p>已提现余额：{{tableData[0].cash_out_money}}</p>
        </div>
        <div class="title mt-20">
            <span>余额明细</span>
        </div>
        <div class="mt-20">
          <el-table :data="moneyList.data" border style="width: 100%">
            <el-table-column
              prop="date"
              label="余额变动场景"
              width="200"
              align="center"
            >
            <template slot-scope="scope">
              <span v-if="scope.row.scene == 10">卖出收入余额</span>
              <span v-if="scope.row.scene == 20">提现支出余额</span>
              <span v-if="scope.row.scene == 30">提现增加冻结金额</span>
              <span v-if="scope.row.scene == 40">分销收入余额</span>
              <span v-if="scope.row.scene == 50">提现成功扣除冻结金额</span>
              <span v-if="scope.row.scene == 60">提现失败返还余额</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="money"
              label="劳动金额"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="describe"
              label="描述"
              width="350"
              align="center"
            >
            </el-table-column>
             <el-table-column
              prop="created_at"
              label="变动时间"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="bottom-paging">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 20, 30, 100]"
            :page-size="num"
            layout="total, prev, pager, next, sizes"
            :total="moneyList.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      isCollapse: false,
      page:1,
      num:10,
      moneyList:[]
    };
  },
  created() {
    this.getInfo();
    this.getUsermoney();
  },
  methods: {
    getUsermoney(){
      this.$get('get/backend_balance_info',{
        user_id:this.$route.query.id,
        page:this.page,
        num:this.num
      }).then(res =>{
        this.moneyList = res.data.data;
      })
    },
    getInfo(){
      this.$get('user/get_info',{
        id:this.$route.query.id
      }).then(res =>{
        this.tableData = [];
        this.tableData.push(res.data.data);
      })
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/user") {
        this.$router.push("/user");
      } else if (index == 1 && this.$route.path != "/withdrawal") {
        this.$router.push("/withdrawal");
      }  else if (index == 2 && this.$route.path != "/balance") {
        this.$router.push("/balance");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.num = val;
      this.getUsermoney();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getUsermoney();
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .date-search {
    margin-top: 30px;
    text-align: right;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 300px;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
